import { useState } from "react";
import { Button, Input, message as antdMessage } from "antd";

const NFCWriter = () => {
  const [message, setMessage] = useState("");

  const writeNFC = async () => {
    if (!("NDEFReader" in window)) {
      antdMessage.error("Web NFC is not supported on this device/browser.");
      return;
    }

    try {
        // eslint-disable-next-line
      const ndef = new NDEFReader();
      await ndef.write(message);
      antdMessage.success("Message written to NFC tag!");
    } catch (err) {
      antdMessage.error(`Error: ${err.message}`);
    }
  };

  return (
    <div className="p-4 border rounded-lg max-w-sm mx-auto space-y-4">
      <h2 className="text-lg font-semibold">NFC Writer</h2>
      <Input
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Enter message"
      />
      <Button type="primary" onClick={writeNFC}>Write to NFC</Button>
    </div>
  );
};

export default NFCWriter;
